import apiService from '@/common/api.service'

export function getFaqList () {
  return apiService({
    url: '/api/faq/list',
    method: 'get'
  })
}

export function getFile (url) {
  return apiService({
    url: '/api/faq/file',
    method: 'get',
    responseType: 'blob',
    params: { file_uri: url }
  })
}
